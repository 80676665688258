@charset "utf-8";

$content-pre-padding: 0.5em;

$footer-background-color: #1b1c1d;

$modal-card-head-padding: 0.9em;
$modal-card-body-padding: 0.9em;

$size-7: 0.9em;

@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/base/_all.sass";
@import "node_modules/bulma/sass/elements/container.sass";
@import "node_modules/bulma/sass/elements/button.sass";
@import "node_modules/bulma/sass/elements/tag.sass";
@import "node_modules/bulma/sass/elements/title.sass";
@import "node_modules/bulma/sass/elements/table.sass";
@import "node_modules/bulma/sass/elements/other.sass";
@import "node_modules/bulma/sass/elements/content.sass";
@import "node_modules/bulma/sass/form/shared.sass";
@import "node_modules/bulma/sass/form/input-textarea.sass";
@import "node_modules/bulma/sass/form/tools.sass";
@import "node_modules/bulma/sass/components/level.sass";
@import "node_modules/bulma/sass/components/modal.sass";
@import "node_modules/bulma/sass/components/message.sass";
@import "node_modules/bulma/sass/layout/section.sass";
@import "node_modules/bulma/sass/layout/footer.sass";

// https://github.com/jgthms/bulma/issues/847
.element {
    &.is-loading {
        position: relative;
        pointer-events: none;
        opacity: 0.5;
        &:after {
            @include loader;
            position: absolute;
            top: calc(50% - 2.5em);
            left: calc(50% - 2.5em);
            width: 5em;
            height: 5em;
            border-width: 0.25em;
        }
    }
}

.title.is-1 {
    font-size: 0.95rem;
}

.title.is-2 {
    font-size: 1.25rem;
}

main header .title {
    margin: 1em 0;
}

.top-icon {
    cursor: pointer;
}

#user-string-list {
    margin-bottom: 2em;

    li {
        pre {
            margin-bottom: 0.4em;
        }

        span.icon {
            margin-left: 0.25em;
            margin-right: 0.5em;
        }
    }
}

#main-nav {
    margin: 1rem 1rem 0 1rem;
}

main .message {
    margin: 0.75em 0;
}

.content ol {
    margin-top: 0;
}

#how-to {
    .modal-card-body {
       font-size: 0.9em; 
    }
}

footer {
    color: white;
}
